interface Props {
  iframe: string | null
}

const IframeReader = ({ iframe }: Props) => {
  const codeHTML = {
    __html: iframe ?? '',
  }

  if (!iframe) {
    return null
  }

  return (
    <div className="iframe__container">
      <div className="iframe__content" dangerouslySetInnerHTML={codeHTML} />
    </div>
  )
}

export default IframeReader
